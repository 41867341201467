import cn from "classnames";
import {
  type ComponentType,
  type FormEvent,
  type FormHTMLAttributes,
  type PointerEvent,
  type ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import type { Merge } from "types/commons";
import { Button, type ButtonProps } from "ui-kit/Button";
import { ICONS } from "ui-kit/ICONS";

import styles from "./ModalDialog.module.scss";
import { modalDialogContext, useModalDialogContext } from "./context";
import { attachEventListeners, detachEventListeners } from "./utils";

const ModalDialogProvider = ({
  id,
  children,
}: {
  id?: string;
  children?: ReactNode;
}) => {
  const dialogRef = useRef<HTMLDialogElement>();

  const cancelDialogEvent = new Event("cancel");

  const openDialog = () => {
    dialogRef.current?.showModal();
  };

  const closeDialog = (returnValue?: string) =>
    dialogRef.current?.close(returnValue);

  const cancelDialog = (returnValue?: string) => {
    dialogRef.current?.dispatchEvent(cancelDialogEvent);
    closeDialog(returnValue);
  };

  return (
    <modalDialogContext.Provider
      value={{
        dialogRef,
        formId: id || "dialogForm",
        openDialog,
        closeDialog,
        cancelDialog,
      }}
    >
      {children}
    </modalDialogContext.Provider>
  );
};

ModalDialogProvider.displayName = "ModalDialogProvider";

const ModalDialog = ({
  children,
  className,
  transitionDuration,
  initialOpen,
  onClose,
  onCancel,
}: {
  children?: ReactNode;
  className?: string;
  transitionDuration?: number; // ms
  initialOpen?: boolean;
  onClose?: (e: Event, options?: boolean | AddEventListenerOptions) => void;
  onCancel?: (e: Event, options?: boolean | AddEventListenerOptions) => void;
}) => {
  const { dialogRef, cancelDialog } = useModalDialogContext();
  const timeout = transitionDuration ?? 500;
  const timeOutId = useRef<number>();

  const handleClickOutside = (e: PointerEvent<HTMLDialogElement>) =>
    e.target === e.currentTarget && cancelDialog();

  const callbackRef = useCallback((node: HTMLDialogElement | null) => {
    if (!node) return;

    //Привязываем dialogRef к элементу
    dialogRef.current = node;

    //Открываем сразу модальный диалог, если требуется
    initialOpen && node.showModal();

    // Создаем обработчик события onClose, но с таймаутом, пока не закончится анимация
    const onCloseWithTimeout =
      onClose &&
      ((e: Event, options?: boolean | AddEventListenerOptions) =>
        (timeOutId.current = setTimeout(
          () => onClose(e, options),
          timeout,
        ) as unknown as number));

    // Привязываем обработчики
    attachEventListeners(node, onCloseWithTimeout, onCancel);

    // На всякий случай очищаем обработчики при размонтировании, но маловероятно, что пригодится
    return () => {
      clearTimeout(timeOutId.current);
      detachEventListeners(node, onCloseWithTimeout, onCancel);
    };
  }, []);

  return (
    <dialog
      ref={callbackRef}
      style={{ "--animation-duration": `${timeout}ms` }}
      className={cn(styles.dialogContainer, className)}
      onPointerDown={handleClickOutside}
    >
      {children}
    </dialog>
  );
};

ModalDialog.displayName = "ModalDialog";

const ModalDialogTrigger = ({
  as,
  variant,
  ...props
}:
  | ({ as: ComponentType<ButtonProps> } & Partial<ButtonProps>)
  | ({ as: undefined } & ButtonProps)) => {
  const { openDialog } = useModalDialogContext();
  const Trigger = as || Button;
  return (
    <Trigger
      variant={variant || "phantom"}
      {...props}
      onClick={(e) => {
        props.onClick?.(e);
        openDialog();
      }}
    />
  );
};

ModalDialogTrigger.displayName = "ModalDialogTrigger";

const ModalDialogCloseButton = ({
  className,
  children,
  variant,
  isCancelButton,
  ...props
}: {
  className?: string;
  children?: ReactNode;
  isCancelButton?: boolean;
} & Partial<ButtonProps>) => {
  const { formId, cancelDialog } = useModalDialogContext();
  return (
    <Button
      {...props}
      className={cn(isCancelButton || styles.closeButton, className)}
      form={formId}
      type="reset"
      variant={variant || "phantom"}
      onClick={() => cancelDialog()}
    >
      {children || <ICONS.Cross />}
    </Button>
  );
};

ModalDialogCloseButton.displayName = "DialogCloseButton";

const ModalDialogContent = ({
  children,
  className,
  preventCloseOnSubmit,
  onSubmit,
  ...props
}: Merge<
  FormHTMLAttributes<HTMLFormElement>,
  {
    preventCloseOnSubmit?: boolean;
    onSubmit?: (
      e: FormEvent<HTMLFormElement>,
      close: (returnValue?: string) => void,
    ) => void;
  }
>) => {
  const { formId, closeDialog } = useModalDialogContext();

  return (
    <form
      id={formId}
      className={cn(styles.dialogForm, className)}
      method="dialog"
      onSubmit={
        (preventCloseOnSubmit || onSubmit) &&
        ((e) => {
          preventCloseOnSubmit && e.preventDefault();
          onSubmit?.(e, closeDialog);
        })
      }
      {...props}
    >
      {children}
    </form>
  );
};

ModalDialogContent.displayName = "ModalDialogContent";

export {
  ModalDialogProvider,
  ModalDialog,
  ModalDialogContent,
  ModalDialogCloseButton,
  ModalDialogTrigger,
};
