import { dateSelector, restaurantSelector } from "features/AppContext";
import { useFetchRestaurantNotesQuery } from "features/api/notes-api";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ETranslations } from "types/translates";

import styles from "./NoteSubheader.module.scss";
import { NotesButton } from "./NotesButton";

export const NoteSubheader = () => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const date = useSelector(dateSelector);
  const restaurant = useSelector(restaurantSelector);
  const hide =
    !pathname.startsWith("/dashboard") &&
    !pathname.startsWith("/hall-scheme") &&
    !pathname.startsWith("/create-booking");
  const { data } = useFetchRestaurantNotesQuery({
    restaurantId: restaurant.restaurant_id,
    date: date.format("YYYY-MM-DD"),
  });
  return !hide && data?.note_records?.[0].content ? (
    <NotesButton
      restaurantId={restaurant.restaurant_id}
      date={date.format("YYYY-MM-DD")}
      containerClassName={styles.noteContainer}
      className={styles.button}
    >
      <strong className={styles.title}>
        {formatMessage({ id: ETranslations.NOTE })}
      </strong>
      {data.note_records[0].content}
    </NotesButton>
  ) : (
    <span className={styles.emptyNote} />
  );
};
