import { Sentry } from "common/sentry";
import { config } from "config";
import dayjs from "dayjs";
import en from "dayjs/locale/en";
import ru from "dayjs/locale/ru";
import { localeSelector } from "features/AppContext/selectors";
import {
  useConfig,
  useFetchTranslatesQuery,
} from "features/api/dictionaries-api";
import { useServiceWorker } from "hooks/useServiceWorker";
import _ from "lodash";
import moment from "moment";
import { type ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { IntlProvider, useIntl } from "react-intl";
import { Provider, useSelector } from "react-redux";
import {
  LoaderFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthService } from "services/auth.service";
import { Notification } from "services/notification";
import { store } from "storage";
import "styles/normalize.scss";
import { ELocales } from "types/commons";
import { ETranslations } from "types/translates";
import { Spinner } from "ui-kit";
import { transformLocale } from "utils";

import "../../index.scss";
import "../../legacy.scss";

export const rootLoader = ({ request }: LoaderFunctionArgs) => {
  const pathname = new URL(request.url).pathname;
  const isRoot = pathname === "/";
  const isLogin = pathname === "/login";
  const authenticated = AuthService.isAuthenticated();
  return authenticated
    ? isRoot || isLogin
      ? redirect("/dashboard")
      : null
    : isLogin
      ? null
      : redirect(`/login?redirectTo=${pathname}`);
};

const DAYJS_LOCALE_DISPATCH = {
  [ELocales.ru_RU]: ru,
  [ELocales.en_EN]: en,
};

const IntlLocaleProvider = ({ children }: { children: ReactNode }) => {
  const { config: config2 } = useConfig();
  console.log(config2);
  const userLocale = useSelector(localeSelector);
  const locale = transformLocale(userLocale);
  const { data: translates } = useFetchTranslatesQuery(locale);

  moment.locale(locale);
  dayjs.locale(DAYJS_LOCALE_DISPATCH[userLocale]);

  return translates ? (
    <IntlProvider defaultLocale={locale} locale={locale} messages={translates}>
      {children}
    </IntlProvider>
  ) : (
    <Spinner />
  );
};

const Root = ({ noAuth }: { noAuth?: boolean }) => {
  useRedirectOnLogout({ disable: noAuth });

  const { reloadPage, showReload, waitingWorker } = useServiceWorker();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (showReload && waitingWorker) {
      Notification.warning({
        options: {
          autoClose: false,
          closeOnClick: false,
          draggable: true,
        },
        title: ETranslations.APP_VERSION_UPDATED,
        message: (
          <div>
            <button className="primary" type="button" onClick={reloadPage}>
              {formatMessage({ id: ETranslations.BASE_REFRESH })}
            </button>
          </div>
        ),
      });
    }
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <>
      <Helmet>
        <title>{config.brand_title}</title>
      </Helmet>
      <Outlet />
      <ToastContainer
        autoClose={3e3}
        position="bottom-right"
        closeOnClick
        draggable
        newestOnTop
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
};

export const RootProvider = ({ noAuth }: { noAuth?: boolean }) => (
  <Provider store={store}>
    <IntlLocaleProvider>
      <Sentry.ErrorBoundary>
        <Root noAuth={noAuth} />
      </Sentry.ErrorBoundary>
    </IntlLocaleProvider>
  </Provider>
);

// Перекинуть на логин, если пользователь разлогинился
function useRedirectOnLogout({ disable }: { disable?: boolean }) {
  const { pathname } = useLocation();
  const [authenticated, setAuthenticated] = useState<boolean | undefined>(
    AuthService.isAuthenticated(),
  );
  const navigate = useNavigate();

  useEffect(() => {
    !disable &&
      !authenticated &&
      !pathname.includes("/login") &&
      navigate(`/logout?redirectTo=${pathname}`);
  }, [authenticated]);

  useEffect(() => {
    global?.channels?.auth && (global.channels.auth.cb = setAuthenticated);
  }, []);
}
