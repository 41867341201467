import { getFullName, getMaskPhone } from "common/helpers";
import type { Source } from "models/source.model";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { ETranslations } from "types/translates";
import { LinkButton } from "ui-kit";
import { ICONS } from "ui-kit";
import { DataSection } from "ui-kit/DataSection/DataSection";

import styles from "./SourceInfo.module.scss";

const SourceInfo = () => {
  const source = useOutletContext<Source>();
  const { formatMessage } = useIntl();
  return (
    <article className={styles.info}>
      <header className={styles.header}>
        <strong className={styles.fullName}>
          {getFullName(
            source.source_contact?.name,
            source.source_contact?.middle_name,
            source.source_contact?.surname,
          ) || "N/A"}
        </strong>
        <LinkButton variant="secondary" to="../edit">
          <ICONS.Edit />
        </LinkButton>
      </header>
      <DataSection.Root
        title={formatMessage({ id: ETranslations.CONTACT })}
        className={styles.dataSection}
        dataListClassName={styles.dataList}
      >
        <DataSection.Item label={formatMessage({ id: ETranslations.PHONE })}>
          {source.source_contact?.phone
            ? getMaskPhone(source.source_contact?.phone)
            : "N/A"}
        </DataSection.Item>
        <DataSection.Item label={formatMessage({ id: ETranslations.EMAIL })}>
          {source.source_contact?.email || "N/A"}
        </DataSection.Item>
        <DataSection.Item label={formatMessage({ id: ETranslations.TYPE })}>
          {source.source_contact?.label
            ? formatMessage({ id: source.source_contact.label })
            : "N/A"}
        </DataSection.Item>
        <DataSection.Item
          label={formatMessage({ id: ETranslations.ORGANIZATION })}
        >
          {source.source_organization?.source_name ||
            formatMessage({ id: ETranslations.WITHOUT_ORGANIZATION })}
        </DataSection.Item>
      </DataSection.Root>
    </article>
  );
};

SourceInfo.displayName = "SourceInfo";

export { SourceInfo };
