import { Modal } from "components/modal";
import type { ReactNode } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import {
  Button,
  ModalDialog,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogProvider,
} from "ui-kit";

import {
  ConfirmMessage,
  ConfirmMessageActions,
  ConfirmMessageHeader,
  ConfirmMessageText,
} from "./ConfirmMessage";
import styles from "./ConfirmModal.module.scss";

export const ConfirmModal = ({
  isOpen,
  header,
  children,
  confirm,
  close,
}: {
  isOpen: boolean;
  header?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  confirm: () => Promise<void>;
  close: () => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Modal.Content as={ConfirmMessage}>
        <ConfirmMessageHeader>{header}</ConfirmMessageHeader>
        <ConfirmMessageText>{children}</ConfirmMessageText>
        <ConfirmMessageActions>
          <Button variant="dangerous" onClick={() => confirm().then(close)}>
            {formatMessage({ id: ETranslations.BASE_CONFIRM })}
          </Button>
          <Button variant="secondary" onClick={close}>
            {formatMessage({ id: ETranslations.BASE_CANCEL })}
          </Button>
        </ConfirmMessageActions>
      </Modal.Content>
    </Modal>
  );
};

export type ConfirmModalDialogProps = {
  variant?: "danger" | "warning" | "neutral";
  header?: ReactNode;
  content?: ReactNode;
  confirmButtonText?: ReactNode;
  customConfirmButton?: ReactNode;
  transitionDuration?: number;
  onConfirm?: () => void;
  onCancel?: (e: Event, options?: boolean | AddEventListenerOptions) => void;
};

export const ConfirmModalDialog = ({
  variant,
  header,
  content,
  confirmButtonText,
  customConfirmButton,
  transitionDuration,
  onConfirm,
  onCancel,
}: ConfirmModalDialogProps) => {
  const { formatMessage } = useIntl();
  return (
    <ModalDialogProvider>
      <ModalDialog
        className={styles.dialog}
        transitionDuration={transitionDuration}
        initialOpen
        onClose={onCancel}
      >
        <ModalDialogCloseButton />
        <ModalDialogContent onSubmit={onConfirm}>
          <ConfirmMessage variant={variant}>
            <ConfirmMessageHeader>{header}</ConfirmMessageHeader>
            <ConfirmMessageText>{content}</ConfirmMessageText>
            <ConfirmMessageActions>
              {customConfirmButton || (
                <Button
                  variant={variant === "danger" ? "dangerous" : "primary"}
                  type="submit"
                >
                  {confirmButtonText}
                </Button>
              )}
              <ModalDialogCloseButton isCancelButton variant="secondary">
                {formatMessage({ id: ETranslations.BASE_CANCEL })}
              </ModalDialogCloseButton>
            </ConfirmMessageActions>
          </ConfirmMessage>
        </ModalDialogContent>
      </ModalDialog>
    </ModalDialogProvider>
  );
};
