import {
  ConfirmMessage,
  ConfirmMessageActions,
  ConfirmMessageHeader,
  ConfirmMessageText,
} from "components/ConfirmModal";
import { FormClose } from "components/MultiStepForm";
import { useState } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import {
  LinkButton,
  ModalDialog,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogProvider,
} from "ui-kit";

const CreateBookingFormCloseDialog = ({ onClose }: { onClose: () => void }) => {
  const { formatMessage } = useIntl();
  return (
    <ModalDialogProvider>
      <ModalDialog initialOpen onClose={onClose}>
        <ModalDialogCloseButton />
        <ModalDialogContent>
          <ConfirmMessage>
            <ConfirmMessageHeader>
              {formatMessage({
                id: ETranslations.CLOSE_BOOKING_HEADER,
              })}
            </ConfirmMessageHeader>
            <ConfirmMessageText>
              {formatMessage({
                id: ETranslations.CLOSE_BOOKING_TEXT,
              })}
            </ConfirmMessageText>
            <ConfirmMessageActions>
              <LinkButton variant="dangerous" to="/dashboard">
                {formatMessage({
                  id: ETranslations.CLOSE_BOOKING_CONFIRM,
                })}
              </LinkButton>
              <ModalDialogCloseButton isCancelButton variant="secondary">
                {formatMessage({ id: ETranslations.BASE_CANCEL })}
              </ModalDialogCloseButton>
            </ConfirmMessageActions>
          </ConfirmMessage>
        </ModalDialogContent>
      </ModalDialog>
    </ModalDialogProvider>
  );
};

export const CrateBookingFormCloseButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <FormClose onClick={() => setIsOpen(true)} />
      {isOpen && (
        <CreateBookingFormCloseDialog onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};
