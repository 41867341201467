import { useDeleteNoteMutation } from "features/api/notes-api";
import type { NoteId, NoteRecordId } from "models/restaurant-notes.model";
import type { Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";
import { Button, ICONS, useModalDialogContextSelector } from "ui-kit";

import styles from "./Note.module.scss";

export const Note = ({
  noteInfo,
  setNoteFormIntent,
}: {
  noteInfo:
    | {
        noteId: NoteId;
        noteRecordId: NoteRecordId;
        noteText: string;
      }
    | undefined;
  setNoteFormIntent: Dispatch<SetStateAction<"create" | "edit" | undefined>>;
}) => {
  const { formatMessage } = useIntl();
  const [deleteNote, deleteMutationState] = useDeleteNoteMutation();
  const closeDialog = useModalDialogContextSelector((c) => c.closeDialog);
  const handleDeleteNote =
    noteInfo &&
    (() =>
      deleteNote({
        noteId: noteInfo.noteId,
        noteRecordId: noteInfo.noteRecordId,
      }).then((d) => {
        const success = Object.hasOwn(d, "data");
        if (success) {
          Notification.success(ETranslations.NOTE_SUCCESSFULLY_DELETED);
          closeDialog();
        } else {
          Notification.error({
            title: ETranslations.NOTE_DELETION_FAILED,
            message: JSON.stringify(deleteMutationState.error),
          });
        }
      }));
  return !noteInfo ? (
    <article>
      {formatMessage({ id: ETranslations.NO_NOTE })}
      <Button variant="primary" onClick={() => setNoteFormIntent("create")}>
        {formatMessage({ id: ETranslations.BASE_CREATE })}
      </Button>
    </article>
  ) : (
    <article>
      <p className={styles.noteText}>{noteInfo.noteText}</p>
      <footer className={styles.footer}>
        <Button variant="primary" onClick={() => setNoteFormIntent("edit")}>
          <ICONS.Edit />
        </Button>
        <Button
          variant="secondary"
          disabled={deleteMutationState.isLoading}
          onClick={handleDeleteNote}
        >
          {formatMessage({ id: ETranslations.BASE_DELETE })}
        </Button>
      </footer>
    </article>
  );
};
