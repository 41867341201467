import { DeepPartial } from "@reduxjs/toolkit";
import { getFullName } from "common/helpers";
import dayjs from "dayjs";
import type { BookingSlot } from "models/booking.model";
import type { Client, ClientId, Contact } from "models/client.model";
import type { Source, SourceId } from "models/source.model";
import type { TagId } from "models/tags.model";
import moment from "moment";
import { TBookingDTO } from "types/IBookingDTO";
import type { BookingOrder } from "types/booking";
import { formatPhone } from "utils";

import { Deposit, FormBooking } from "./types";

export function castOrderToForm(
  order: DeepPartial<BookingOrder>,
): DeepPartial<FormBooking> {
  const {
    bookingDate,
    bookingTime,
    client,
    comment,
    persons,
    places,
    source,
    useDeposit,
    depositAmount,
    visitTime,
    orderId,
  } = order;

  const firstPlace = places?.[0];
  const deposit: Deposit = {
    use_deposit: !!useDeposit,
    deposit_made: false,
    deposit_amount: depositAmount || 0,
  };
  return {
    bookingId: orderId,
    tables: places?.map((table) => table?.id) || [],
    placeId: Number(firstPlace?.placeId),
    booking_date: bookingDate,
    booking_time: bookingTime,
    client_id: Number(client?.client_id),
    client: client as Client | Contact,
    comment,
    persons,
    phone: client?.phone,
    restaurant_id: firstPlace?.place?.restaurantId,
    source: source && {
      id: source.source_id as SourceId,
      name:
        getFullName(
          source.source_contact?.name,
          source.source_contact?.middle_name,
          source.source_contact?.surname,
        ) || "N/A",
    },
    tags: [],
    deposit,
    visit_time: visitTime,
  };
}

export function castBookingToForm(
  booking: DeepPartial<BookingSlot>,
): DeepPartial<FormBooking> {
  const firstPlace = booking?.slot_place?.[0];
  const deposit: Deposit = {
    use_deposit: booking.slot_deposit?.use_deposit || false,
    deposit_made: booking.slot_deposit?.deposit_status === "MADE" || false,
    deposit_amount: booking.slot_deposit?.deposit_amount || 0,
  };
  return {
    bookingId: booking.slot_id as number,
    tables: booking.slot_place?.map((table) => table?.table_id),
    placeId: firstPlace?.place_id,
    booking_date: booking.date,
    booking_time: booking.time?.split(":").slice(0, 2).join(":"),
    client_id: booking.client?.client_id as ClientId,
    client: booking.client,
    comment: booking.comment,
    deposit,
    persons: booking.visitors,
    phone: formatPhone(booking.client?.phone),
    //FIXME: типизация
    //@ts-ignore
    source: booking.source && {
      id: booking.source.id,
      name:
        getFullName(
          booking.source.contact?.name,
          booking.source.contact?.middle_name,
          booking.source.contact?.surname,
        ) || "N/A",
    },
    tags: booking?.tags?.map((tag) => ({
      ...tag,
      description: tag?.name,
      value: tag?.id as TagId,
    })),
    visit_time: booking.visit_duration,
    notes: Object.values(booking.notes || {}).join(", "),
    rawNotes: booking.notes && Object.values(booking.notes).join(" "),
    seatType: booking.seat_type,
  };
}

export const castFormToUpdateDTO = (formData: FormBooking): TBookingDTO => {
  const {
    placeId,
    tables: tablesSrc,
    hostess,
    tags,
    client,
    time,
    bookingId,
    date,
    deposit,
    restaurant_id,
    client_id,
    notes,
    rawNotes,
    ...other
  } = formData;
  const { use_deposit, deposit_made, deposit_amount } = deposit;
  const tables = [];
  if (placeId) {
    tables.push({
      place_id: placeId,
      table_ids: tablesSrc,
    });
  }
  const timestampDate = moment(Number(time.value));

  return {
    tables,
    restaurant_id,
    ...other,
    source: other.source?.id && { id: other.source.id },
    user_id: hostess.id,
    notes: { note: rawNotes },
    client,
    use_deposit,
    deposit_status: deposit_made,
    deposit_amount,
    tags: tags.map((t) => ({
      description: t.description || t.label,
      color: t.color,
      tag_id: t.value,
    })),
    booking_time: timestampDate.isValid()
      ? timestampDate.format("HH:mm:ss")
      : moment(time.label, "HH:mm").format("HH:mm:ss"),
    booking_date: timestampDate.isValid()
      ? timestampDate.format("YYYY-MM-DD")
      : moment(date).format("YYYY-MM-DD"),
  };
};

const nextTime = () => {
  const now = dayjs.tz();
  const diff = now.minute() % 15;
  return now.clone().add(diff, "minute").format("HH:mm");
};

export const castFormToCreateDTO = (
  formData: FormBooking,
): Partial<TBookingDTO> => {
  const {
    placeId,
    tables,
    hostess,
    persons,
    client,
    client_id,
    bookingId,
    deposit,
    comment = "",
    phone,
    restaurant_id,
    time,
    seatType,
    ...rest
  } = formData;

  let date = dayjs.tz(Number(time?.value));

  if (!date.isValid()) {
    const timeString = time?.label || nextTime();
    date = dayjs
      .tz(rest.date)
      .set("hour", +timeString.substring(0, 2))
      .set("minute", +timeString.substring(3, 5))
      .set("seconds", 0);
  }
  const tags =
    rest.tags?.map((t) => ({
      description: t.label,
      color: t.color,
      tag_id: t.value,
    })) || [];
  const source = rest.source ? { id: rest.source.id } : null;
  const { use_deposit, deposit_made, deposit_amount } = deposit;

  return {
    booking_date: date.format("YYYY-MM-DD"),
    booking_time: date.format("HH:mm:ss"),
    phone,
    client,
    comment,
    deposit_amount,
    use_deposit,
    deposit_status: deposit_made,
    persons: Number(persons),
    seatType,
    restaurant_id,
    // @ts-ignore
    tables: [
      {
        place_id: placeId,
        table_ids: tables,
      },
    ],
    tags,
    user_id: hostess.value,
    visit_time: rest.visit_time,
  };
};
