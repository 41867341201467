import type { RequiredBy } from "types/commons";
import { ETranslations } from "types/translates";
import { z } from "zod";

import { PageableScheme } from "./common";

export const OrganizationId = z.number().int().brand("OrganizationId");
export type OrganizationId = z.infer<typeof OrganizationId>;

export const OrganizationName = z.string().brand("OrganizationName");
export type OrganizationName = z.infer<typeof OrganizationName>;

export const SourceId = z.number().int().brand("SourceId");
export type SourceId = z.infer<typeof SourceId>;

export const SourceContactId = z.number().int().brand("SourceContactId");
export type SourceContactId = z.infer<typeof SourceContactId>;

export const SourceType = z.enum(
  [
    "CONCIERGE",
    "PERSONAL_ASSISTANT",
    "LIFESTYLE_MANAGER",
    "PRIME_CONCIERGE",
    "OPEN_SOURCE",
  ],
  { message: ETranslations.FIELD_INVALID },
);

export type SourceType = z.infer<typeof SourceType>;

const SourceContact = z.object({
  id: SourceContactId,
  label: SourceType,
  phone: z.string().nullish(),
  extension_phone: z.string().nullish(),
  email: z.string().nullish(),
  surname: z.string().nullish(),
  middle_name: z.string().nullish(),
  name: z.string().nullish(),
});
export type SourceContact = z.infer<typeof SourceContact>;

export const SourceContactWithOldSources = SourceContact.extend({
  id: SourceContactId.nullable(),
  label: SourceType.nullable(),
}).nullable();

export const Organization = z.object({
  id: OrganizationId,
  source_name: OrganizationName,
  organization_type: z.string().nullable().optional(),
  address: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  organization_uuid: z.string().uuid().nullable().optional(),
});
export type Organization = z.infer<typeof Organization>;

const SourceWithOrganization = z.object({
  source_id: SourceId,
  source_contact: SourceContactWithOldSources,
  source_type: z.literal("ORGANIZATION_PERSON"),
  source_organization: Organization,
});

const SourceWithOutOrganization = z.object({
  source_id: SourceId,
  source_contact: SourceContactWithOldSources,
  source_type: z.literal("PERSONAL"),
  source_organization: z.null().optional(),
});

export const Source = z.discriminatedUnion("source_type", [
  SourceWithOrganization,
  SourceWithOutOrganization,
]);
export type Source = z.infer<typeof Source>;

export const SourceSearchDTO = z.object({
  content: Source.array(),
  ...PageableScheme,
});
export type SourceSearchDTO = z.infer<typeof SourceSearchDTO>;

export type SourceSearchParams = Partial<{
  s: string;
  labels: SourceType[];
}>;

export const OrganizationSearchDTO = z.object({
  content: z
    .object({
      id: OrganizationId,
      name: OrganizationName,
      type: z.string().nullable(),
      address: z.string().nullable(),
      phone: z.string().nullable(),
      organization_uuid: z.string().uuid().nullable(),
      tenant_id: z.number().nullable(),
    })
    .array(),
  ...PageableScheme,
});

export type OrganizationSearchDTO = z.infer<typeof OrganizationSearchDTO>;
