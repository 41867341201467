import { useApplicationContextActions } from "features/AppContext";
import { useCreateNewBookingMutation } from "features/api/bookings-api";
import type { PlaceId, RestaurantId } from "models/restaurant.model";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notification } from "services/notification";
import type { TBookingDTO } from "types/IBookingDTO";
import type { ErrorResponse } from "types/commons";
import { ETranslations } from "types/translates";

import { bookingFormLocalStore } from "./localStore/bookingFormLocalStore";
import { BookingData } from "./model";

export const useCreateBooking = () => {
  const [isOverbookingModalOpen, setIsOverbookingModalOpen] = useState(false);
  const [create, { isLoading }] = useCreateNewBookingMutation();
  const { setRestaurant } = useApplicationContextActions();
  const navigate = useNavigate();
  const createBooking = ({
    formData,
    restaurantId,
    isOverbooking,
  }: {
    formData: BookingData;
    restaurantId: RestaurantId;
    isOverbooking?: boolean;
  }) => {
    const parsedData = BookingData.parse(formData);
    const tables = parsedData.tables.reduce(
      (acc, table) => {
        if (acc.placeIdsIndexes.has(table.placeId)) {
          const placeIdIndex = acc.placeIdsIndexes.get(table.placeId)!;
          acc.result[placeIdIndex].table_ids.push(table.tableId);
        } else {
          acc.result.push({
            place_id: table.placeId,
            table_ids: [table.tableId],
          });
        }

        return acc;
      },
      {
        result: [] as TBookingDTO["tables"],
        placeIdsIndexes: new Map<PlaceId, number>(),
      },
    ).result;

    const payload: Partial<TBookingDTO> = {
      tables,
      tags: parsedData.tags?.map((t) => ({
        tag_id: t.id,
        color: t.color,
        description: t.name,
      })),
      //@ts-ignore
      client: {
        client_id: parsedData.client.clientId,
        name: parsedData.client.name || "",
        surname: parsedData.client.surname,
        middle_name: parsedData.client.middleName,
        phone: parsedData.client.phone,
        contact_type: parsedData.client.contactType,
      },
      places: tables,
      visit_time: parsedData.visitDuration,
      restaurant_id: restaurantId,
      booking_time: parsedData.bookingTime,
      booking_date: parsedData.bookingDate,
      persons: parsedData.persons,
      comment: parsedData.comment,
      phone: parsedData.client.phone,
      use_deposit: parsedData.deposit.useDeposit,
      deposit_status: parsedData.deposit.depositMade,
      deposit_amount: parsedData.deposit.depositAmount,
      seatType: "BOOK",
      booked_by: parsedData.userId,
      source: parsedData.source?.id && { id: parsedData.source?.id },
    };

    return create({
      data: payload,
      isOverbooking,
    })
      .unwrap()
      .then(() => {
        Notification.success(ETranslations.BOOKING_CREATE_SUCCESSFULLY);
        bookingFormLocalStore.clear();
        setRestaurant(restaurantId);
        navigate("/dashboard");
      })
      .catch((e) => {
        const errorData = (e as ErrorResponse["error"])?.data;
        if (errorData?.errorCode === 10100)
          return setIsOverbookingModalOpen(true);
        Notification.error(
          errorData?.errorCode === 10600
            ? {
                title: ETranslations.UNABLE_TO_CREATE_BOOKING_COVERAGE,
              }
            : {
                title: ETranslations.UNABLE_TO_CREATE_BOOKING,
                message: errorData?.errorMessage,
              },
        );
      });
  };
  return {
    createBooking,
    isLoading,
    isOverbookingModalOpen,
    closeOverbookingModal: () => setIsOverbookingModalOpen(false),
  };
};
