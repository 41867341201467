import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import objectSupport from "dayjs/plugin/objectSupport";
import type { DateISO, MonthISO } from "types/commons";

import styles from "./SmallCalendar.module.scss";

dayjs.extend(localeData);
dayjs.extend(objectSupport);

const getDateISO = (formattedDate: `${number}-${MonthISO}-`, day: number) =>
  (formattedDate + `${day}`.padStart(2, "0")) as DateISO;

const renderWeekdays = (
  weekdays: string[],
  weekStartsFrom: number,
  render: (weekday: string) => JSX.Element,
) => {
  const result = new Array<JSX.Element>();
  for (let i = weekdays.length - weekStartsFrom; i >= 1 - weekStartsFrom; i--) {
    result.push(render(weekdays.at(-i)!));
  }
  return result;
};

export const SmallCalendar = ({
  name,
  defaultDateISO,
  year,
  month,
  enabledDaysSet,
}: {
  name: string;
  defaultDateISO: DateISO;
  year: number;
  month: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | (number & {});
  enabledDaysSet: Set<DateISO>;
}) => {
  const currentLocaleData = dayjs.localeData();
  const weekStartsFrom = currentLocaleData.firstDayOfWeek();
  const weekdays = currentLocaleData.weekdaysMin();
  const date = dayjs({ year, month }).utc(true);
  const formattedDate = date.format("YYYY-MM-") as `${number}-${MonthISO}-`;

  return (
    <article className={styles.calendar}>
      <ol className={styles.weekdays}>
        {renderWeekdays(weekdays, weekStartsFrom, (weekday) => (
          <li key={weekday}>{weekday}</li>
        ))}
      </ol>
      <ol
        className={styles.days}
        style={{
          "--first-weekday": date.day() + 1 - weekStartsFrom || 7,
        }}
      >
        {Array.from({ length: date.daysInMonth() }, (_, dayIndex) => {
          const dateISO = getDateISO(formattedDate, dayIndex + 1);
          return (
            <li key={dateISO}>
              <input
                id={dateISO}
                type="radio"
                hidden
                name={name}
                value={dateISO}
                disabled={!enabledDaysSet.has(dateISO)}
                defaultChecked={dateISO === defaultDateISO}
              />
              <label className={styles.label} htmlFor={dateISO} />
            </li>
          );
        })}
      </ol>
    </article>
  );
};
