export const attachEventListeners = (
  node: HTMLDialogElement,
  onClose?: (e: Event, options?: boolean | AddEventListenerOptions) => void,
  onCancel?: (e: Event, options?: boolean | AddEventListenerOptions) => void,
) => {
  onClose && node.addEventListener("close", onClose);
  onCancel && node.addEventListener("cancel", onCancel);
};

export const detachEventListeners = (
  node: HTMLDialogElement,
  onClose?: (e: Event, options?: boolean | AddEventListenerOptions) => void,
  onCancel?: (e: Event, options?: boolean | AddEventListenerOptions) => void,
) => {
  onClose && node.removeEventListener("close", onClose);
  onCancel && node.removeEventListener("cancel", onCancel);
};
